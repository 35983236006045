
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class QuestionnarieService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getQuestionnaries() {
    return this.http.get(this.API_END_POINT + '/questionnaire/questionnaire');
  }

  createQuestionnarie(questionnaire) {
    return this.http.post(this.API_END_POINT + '/questionnaire/questionnaire', questionnaire)
  }

  updateQuestionnarie(questionnaire) {
    return this.http.put(this.API_END_POINT + '/questionnaire/questionnaire', questionnaire)
  }

  getResultSets(questionnaireId) {
    return this.http.get(this.API_END_POINT + '/questionnaire/resultsets/' + questionnaireId);
  }

  updateScores(questionnaireId) {
    return this.http.put(this.API_END_POINT + '/questionnaire_scores/' + questionnaireId, {});
  }

  createResultSets(resultSet) {
    return this.http.post(this.API_END_POINT + '/questionnaire/resultsets', resultSet)
  }

  updateResultSets(resultSet) {
    return this.http.put(this.API_END_POINT + '/questionnaire/resultsets/', resultSet)
  }

  deleteResultSets(resultSetId) {
    return this.http.put(this.API_END_POINT + '/questionnaire/resultsets/', resultSetId)
  }

  getQuestions(questionnaireId) {
    return this.http.get(this.API_END_POINT + '/questionnaire/questions/' + questionnaireId);
  }

  addQuestion(questionnaireId, question) {
    return this.http.post(this.API_END_POINT + '/questionnaire/questions/' + questionnaireId, question);
  }

  removeQuestion(questionId) {
    return this.http.delete(this.API_END_POINT + '/questionnaire/questions/' + questionId);
  }

  updateQuestion(question) {
    return this.http.put(this.API_END_POINT + '/questionnaire/questions/' + question.questionId, question);
  }

  addQuestionOptions(option) {
    return this.http.post(this.API_END_POINT + '/questionnaire/options/' + option.questionId, option);
  }

  removeQuestionOptions(questionChoiceId) {
    return this.http.delete(this.API_END_POINT + '/questionnaire/options/' + questionChoiceId);
  }

  updateQuestionOptions(option) {
    return this.http.put(this.API_END_POINT + '/questionnaire/options/' + option.questionChoiceId, option);
  }

  getAnalytics(questionId) {
    return this.http.get(this.API_END_POINT + '/questionnaire/analytics/' + questionId);
  }

  updateChoicePositions(questionId, data) {
    return this.http.put(this.API_END_POINT + '/questionnaire/option_positions/' + questionId, data);
  }

}
