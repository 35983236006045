import { ViewCell } from 'ng2-smart-table';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-image-view',
  template: `
  <img src="{{ renderValue }}" style="max-width:100px;max-height:100px;" *ngIf="renderValue"/>
  `,
})
export class ImageViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.value) {
      this.renderValue = this.value.toString();
    }
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}