import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';


import { AssessmentService } from '../../../../@core/services';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ngx-assessment-edit-question',
  templateUrl: './edit-question.component.html',
})
export class AssessmentEditQuestionComponent {

  question: any
  questionnaireId: Number = 0
  selectedLanguage = 'english'
  quickOptionType = 'custom'
  API_URL_CHOICE = ''
  API_URL_QUESTION = ''

  CKConfig: any = {
    toolbar: [['Styles', 'Format', 'Font', 'FontSize'],
      '/',
    ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste', 'Find', 'Replace', '-', 'Outdent', 'Indent', '-', 'Print'],
      '/',
    ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    ],
  }

  constructor(protected windowRef: NbWindowRef,
    private assessmentService: AssessmentService,
    private toastr: NbToastrService) {

    this.question = this.windowRef.config.context['question']
    this.questionnaireId = this.windowRef.config.context['questionnaireId']
    this.API_URL_CHOICE = environment.API_END_POINT + '/images/upload?path=images/questionnaries/assessments/' + this.questionnaireId + '/options'
    this.API_URL_QUESTION = environment.API_END_POINT + '/images/upload?path=images/questionnaries/assessments/' + this.questionnaireId + '/question'

  }


  updateQuestion(event) {

    this.assessmentService.updateQuestion(this.question).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })

  }


  addChoiceBase(text) {
    const options = {
      optionText: text,
      optionImage: '',
      questionId: this.question.questionId
    }

    return new Promise((resolve, reject) => {
      this.assessmentService.addQuestionOptions(options).subscribe((res: any) => {
        this.question.choices.push(res.choice)
        return resolve();
      })
    })

  }

  addChoice() {

    if (this.quickOptionType === 'custom') {
      this.addChoiceBase('')
    } else if (this.quickOptionType === 'boolean') {
      this.addChoiceBase('Yes').then(() => {
        this.addChoiceBase('No')
      })

    } else if (this.quickOptionType === 'agreedisagree') {
      this.addChoiceBase('Strongly Agree').then(() => {
        return this.addChoiceBase('Agree')
      }).then(() => {
        return this.addChoiceBase('Disagree')
      }).then(() => {
        return this.addChoiceBase('Strongly Disagree')
      })

    }

  }

  removeChoice(choiceIndex) {

    this.assessmentService.removeQuestionOptions(this.question.choices[choiceIndex].optionId)
      .subscribe((res) => {
        this.question.choices.splice(choiceIndex, 1)
      })

  }

  updateChoice(choiceIndex) {

    const choice = this.question.choices[choiceIndex]

    console.log(choice)
    this.assessmentService.updateQuestionOptions(choice).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })
  }

  onUploadFinishedOnChoice(choiceIndex, event) {
    this.question.choices[choiceIndex].optionImage = event.serverResponse.response.body.path
    this.assessmentService.updateQuestionOptions(this.question.choices[choiceIndex]).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })
  }

  onUploadFinished(event) {
    this.question.questionImage = event.serverResponse.response.body.path
    this.assessmentService.updateQuestion(this.question).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })

  }

  onQuestionImageRemoved() {
    this.question.questionImage = ''
    this.assessmentService.updateQuestion(this.question).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })
  }

  onOptionImageRemoved(choiceIndex) {
    this.question.choices[choiceIndex].optionImage = ''
    this.assessmentService.updateQuestionOptions(this.question.choices[choiceIndex]).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Updated !')
      }
    })
  }

}

