import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import _ from 'lodash';
import { environment } from '../../../../../environments/environment'


@Component({
  selector: 'ngx-view-subscriber',
  templateUrl: './view-subscriber.component.html',
})
export class ViewSubscriberComponent {

  subscriber: any
  API_URL = environment.API_URL

  constructor(
    protected windowRef: NbWindowRef,
  ) {
    this.subscriber = this.windowRef.config.context['subscriber']
  }

}

