
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

import { environment } from '../../../environments/environment';

@Injectable()
export class SalesService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getLeads(offset) {
    return this.http.get(this.API_END_POINT + '/leads/' + offset);
  }

  downloadLeads(details) {

    this.http.post(this.API_END_POINT + '/leads/download', details, { responseType: 'blob', observe: 'response' })
      .subscribe((response: any) => {

        const blob = new Blob([response.body], { type: 'text/csv' });
        saveAs(blob, 'leads.csv');

      });
  }

  searchLeads(keyword, offset) {
    return this.http.get(this.API_END_POINT + '/leads/search/' + keyword + '/' + offset);
  }

  getPurchases(offset, status) {
    return this.http.get(this.API_END_POINT + '/purchases/' + offset + '?status=' + status);
  }

  capturePayment(details) {
    return this.http.put(this.API_END_POINT + '/purchases/capture', details);
  }

  searchPurchases(keyword, offset) {
    return this.http.get(this.API_END_POINT + '/purchases/search/' + keyword + '/' + offset);
  }

  createInvoice(invoice) {
    return this.http.post(this.API_END_POINT + '/purchases/invoice', invoice);
  }

  updatePurchase(purchase) {
    return this.http.put(this.API_END_POINT + '/purchases', purchase);
  }

  getPurchase(purchaseId) {
    return this.http.get(this.API_END_POINT + '/purchase/' + purchaseId);
  }

}
