import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { ContentService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-postlink',
  templateUrl: './create-postlink.component.html',
})
export class CreatePostLinkComponent {

  postLink = {
    postTitle: '',
    postLink: '',
    postImage: '',
    visible: 0,
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=cms/posts/postlinks'

  constructor(private contentService: ContentService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createPostLink() {

    if (!this.postLink.postTitle) {
      return this.toastr.warning('', 'Title is mandatory')
    }

    if (!this.postLink.postLink) {
      return this.toastr.warning('', 'Link is mandatory')
    }

    if (!this.postLink.postImage) {
      return this.toastr.warning('', 'Image for Post is mandatory')
    }

    this.contentService.createPostLink(this.postLink).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Post  Added !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to create Post')
    })

  }

  onUploadFinished(event) {
    this.postLink.postImage = event.serverResponse.response.body.path
  }

}

