import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import { UserService } from '../../../@core/services';

@Component({
  selector: 'ngx-user-edit',
  templateUrl: './user-edit.component.html',
})
export class UserEditComponent {


  user: any

  constructor(protected windowRef: NbWindowRef,
    private userService: UserService,
    private toastr: NbToastrService) {

    this.user = this.windowRef.config.context['user']

  }

  updateUser() {

    this.userService.updateUser(this.user).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'User Updated !')
        this.windowRef.close()
      }
    })


  }

}

