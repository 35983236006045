import { Component } from '@angular/core';
import { NbToastrService, NbWindowService, NbWindowRef } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService, LibraryService } from '../../../../@core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-stream-match-editor',
  templateUrl: './stream-match-editor.component.html',
})
export class StreamMatchEditorComponent {

  API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  streams = []
  questionnaireId = ''
  choice: any

  resultSet: any


  constructor(private questionnarieService: QuestionnarieService,
    private streamService: LibraryService,
    private toastr: NbToastrService,
    private windowService: NbWindowService,
    protected windowRef: NbWindowRef,
  ) {

    this.resultSet = this.windowRef.config.context['resultSet']

    const originalStreamMatch = this.resultSet['streamMatch']

    this.streamService.getStreams().subscribe((res: any) => {

      if (res.success) {
        this.streams = res.streams

        this.streams.forEach((stream) => {

          originalStreamMatch.forEach((streamMatch) => {
            if (stream.streamId == streamMatch.streamId) {
              stream.score = streamMatch.score
            }
          })

          if (!stream.score) {
            stream.score = 0
          }
        })

      }

    })

  }

  saveStreamResultSetData() {
    const streamEval = []

    this.streams.forEach((set, index) => {
      streamEval.push({
        streamId: set.streamId,
        streamName: set.streamName,
        score: set.score
      })
    })

    const _resultSet = JSON.parse(JSON.stringify(this.resultSet))

    _resultSet.streamMatch = streamEval

    this.questionnarieService.updateResultSets(_resultSet).subscribe((res: any) => {
      if (res.success) {
        this.windowRef.close()
      }
    })

  }

}

