import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class ContentService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  /* Post Links */

  getPostLinks() {
    return this.http.get(this.API_END_POINT + '/content/postlinks');
  }

  createPostLink(postLink) {
    return this.http.post(this.API_END_POINT + '/content/postlinks', postLink)
  }

  updatePostLink(postLink) {
    return this.http.put(this.API_END_POINT + '/content/postlinks', postLink)
  }

  deletePostLink(postLinkId) {
    return this.http.delete(this.API_END_POINT + '/content/postlinks/' + postLinkId)
  }

}
