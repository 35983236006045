import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { DepartmentService, LibraryService } from '../../../../@core/services';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ngx-create-department',
  templateUrl: './create-department.component.html',
})
export class CreateDepartmentComponent {

  department = {
    streamId: '',
    departmentName: '',
    departmentImage: '',
    slug: ''
  }

  API_URL = environment.API_END_POINT + '/images/upload?path=images/departments'
  streams = []
  images = []

  constructor(private departmentService: DepartmentService,
    private streamService: LibraryService,
    protected windowRef: NbWindowRef,
    private toastr: NbToastrService) {
    this.streamService.getStreams().subscribe((res: any) => {
      if (res.success) {
        this.streams = res.streams
      }
    })
  }

  createDepartment() {

    if (!this.department.streamId) {
      return this.toastr.warning('Choose a Stream')
    }

    if (!this.department.departmentName) {
      return this.toastr.warning('Please enter department Name')
    }

    if (!this.department.departmentImage) {
      return this.toastr.warning('Please choose a department image')
    }

    this.departmentService.createDepartment(this.department).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Department Created')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to add Department')
    })
  }

  onUploadFinished(event) {
    this.department.departmentImage = event.serverResponse.response.body.path
  }


  onDepartmentNameChange() {
    this.department.slug = this.department.departmentName.toLocaleLowerCase()
  }

}
