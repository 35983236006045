import { ViewCell } from 'ng2-smart-table';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-toggle-button',
  template: `
  <span>
  <p *ngIf="checked">visible</p>
  <p *ngIf="!checked">hidden</p>
  </span>
  `,
})
export class ToggleButtonViewComponent implements ViewCell, OnInit {
  checked: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.value) {
      this.checked = this.value.toString();
    }
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}
