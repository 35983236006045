import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { DepartmentService, LibraryService } from '../../../../@core/services';

import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-edit-department',
  templateUrl: './edit-department.component.html',
})
export class EditDepartmentComponent {

  department: any

  API_URL = environment.API_END_POINT + '/images/upload?path=images/departments'
  streams = []
  images = []

  constructor(private departmentService: DepartmentService,
    private streamService: LibraryService,
    protected windowRef: NbWindowRef,
    private router: Router,
    private toastr: NbToastrService) {
    this.streamService.getStreams().subscribe((res: any) => {
      if (res.success) {
        this.streams = res.streams
      }
    })
    this.department = this.windowRef.config.context
    this.images = [this.department.departmentImage]
  }

  updateDepartment() {

    if (!this.department.streamId) {
      return this.toastr.warning('Choose a Stream')
    }

    if (!this.department.departmentName) {
      return this.toastr.warning('Please enter department Name')
    }

    if (!this.department.departmentImage) {
      return this.toastr.warning('Please choose a department image')
    }

    delete this.department.streamName

    this.departmentService.updateDepartment(this.department).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Department Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Department')
    })
  }

  onUploadFinished(event) {
    this.department.departmentImage = event.serverResponse.response.body.path
  }


  onDepartmentNameChange() {
    this.department.slug = this.department.departmentName.toLocaleLowerCase()
  }

  editContent() {
    this.windowRef.close();
    this.router.navigate(['/pages/departments/content/', this.department.departmentId])
  }

}
