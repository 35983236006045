import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-entrance',
  templateUrl: './edit-entrance.component.html',
})
export class EditEntranceComponent {

  entrance: any
  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/entrances/logo'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.entrance = this.windowRef.config.context
    this.entrance.formIssueDate = new Date(this.entrance.formIssueDate)
    this.entrance.lastDate = new Date(this.entrance.lastDate)
    this.images = [this.entrance.image]

  }

  updateEntrance() {

    if (!this.entrance.examName) {
      return this.toastr.warning('', 'Exam Name is Mandatory')
    }

    if (!this.entrance.image) {
      return this.toastr.warning('', 'Image for Entrance is Mandatory')
    }

    const entrance = _.cloneDeep(this.entrance)
    entrance.formIssueDate = this.formatDate(entrance.formIssueDate)
    entrance.lastDate = this.formatDate(entrance.lastDate)

    this.streamService.updateEntrance(entrance).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Entrance Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Entrance')
    })

  }

  onUploadFinished(event) {
    this.entrance.image = event.serverResponse.response.body.path
  }

  formatDate(date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate()
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

}

