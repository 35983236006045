import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { AssessmentService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-assessment-questionnaire',
  templateUrl: './create-questionnaire.component.html',
})
export class CreateAssessmentQuestionnaireComponent {


  questionnaire = {
    questionnaireName: '',
    imageUrl: '',
  }

  API_URL = ''

  images = []

  constructor(private assessmentService: AssessmentService) {

  }

  createQuestionnaire() {


    this.assessmentService.createQuestionnaire(this.questionnaire).subscribe((res: any) => {

    })
  }

  onUploadFinished(event) {

  }

}

