import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-questionnaire',
  templateUrl: './edit-questionnaire.component.html',
})
export class EditQuestionnaireComponent {

  questionnaire: any
  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/questionnaries/questionnarie'

  constructor(protected windowRef: NbWindowRef,
    private toastr: NbToastrService,
    private questionnaireService: QuestionnarieService) {

    this.questionnaire = this.windowRef.config.context
    this.images = [this.questionnaire.questionnaireImage]

  }

  updateQuestionnaire() {

    if (!this.questionnaire.questionnaireName) {
      return this.toastr.warning('', 'Questionnaire Name is Mandatory')
    }

    if (!this.questionnaire.questionnaireImage) {
      return this.toastr.warning('', 'Image for Questionnaire is Mandatory')
    }

    this.questionnaireService.updateQuestionnarie(this.questionnaire).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Questionnaire  Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Questionnaire')
    })
  }

  onUploadFinished(event) {
    this.questionnaire.questionnaireImage = event.serverResponse.response.body.path
  }

}

