import { Component, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

import { UserService } from '../../../@core/services';


@Component({
  selector: 'ngx-edit-plan-upgrade-request',
  templateUrl: './edit-plan-upgrade-request.component.html',
})
export class EditPlanUpgradeRequestComponent {

  request: any = undefined

  constructor(
    protected windowRef: NbWindowRef,
    private userService: UserService
  ) {

    this.request = this.windowRef.config.context['requestId']

  }


  updateRequest() {

    this.userService.updatePlanUpgradeRequest(this.request).subscribe((res: any) => {
      if (res.success) {
        this.windowRef.close()
      }

    })

  }
}

