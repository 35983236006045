import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-entrance',
  templateUrl: './create-entrance.component.html',
})
export class CreateEntranceComponent {

  entrance = {
    examName: '',
    image: '',
    website: '',
    enabled: 1,
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/entrances/logo'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createEntrance() {

    if (!this.entrance.examName) {
      return this.toastr.warning('', 'Exam Name is Mandatory')
    }

    if (!this.entrance.image) {
      return this.toastr.warning('', 'Image for Entrance is Mandatory')
    }

    this.streamService.createEntrance(this.entrance).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Entrance  Added !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to create Entrance')
    })

  }

  onUploadFinished(event) {
    this.entrance.image = event.serverResponse.response.body.path
  }

}

