import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService, CourseService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-group',
  templateUrl: './create-group.component.html',
})
export class CreateCourseGroupComponent {

  group = {
    courseGroupName: '',
  }

  images = []

  constructor(private courseService: CourseService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createStream() {

    if (!this.group.courseGroupName) {
      return this.toastr.warning('', 'Group Name is Mandatory')
    }

    this.courseService.createCourseGroup(this.group).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Group  Created')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to add Group')
    })
  }

}

