import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import _ from 'lodash';

import { AdminService } from '../../../@core/services';

@Component({
  selector: 'ngx-edit-admin',
  templateUrl: './edit-admin.component.html',
})
export class EditAdminComponent {

  admin: any

  defaultRoles = [
    { name: 'Streams', id: 'streams', read: false, create: false, edit: false, delete: false },
    { name: 'Departments', id: 'departments', read: false, create: false, edit: false, delete: false },
    { name: 'Colleges', id: 'colleges', read: false, create: false, edit: false, delete: false },
    { name: 'Entrances', id: 'entrances', read: false, create: false, edit: false, delete: false },
    { name: 'Class Groups', id: 'classgroups', read: false, create: false, edit: false, delete: false },
    { name: 'Scholarships', id: 'scholarships', read: false, create: false, edit: false, delete: false },
    { name: 'Questionnaires', id: 'questionnaires', read: false, create: false, edit: false, delete: false },
    { name: 'Content', id: 'content', read: false, create: false, edit: false, delete: false },
    { name: 'Report Data Editor', id: 'reportdataeditor', read: false, create: false, edit: false, delete: false },
    { name: 'Master Class', id: 'masterclass', read: false, create: false, edit: false, delete: false },
    { name: 'Sessions', id: 'sessions', read: false, create: false, edit: false, delete: false },
    { name: 'Session Settings', id: 'sessionsettings', read: false, create: false, edit: false, delete: false },
    { name: 'Session Subscribers', id: 'sessionsubscribers', read: false, create: false, edit: false, delete: false },
    { name: 'Session Requests', id: 'sessionrequests', read: false, create: false, edit: false, delete: false },
    { name: 'Leads', id: 'leads', read: false, create: false, edit: false, delete: false },
    { name: 'Purchases', id: 'purchases', read: false, create: false, edit: false, delete: false },
    { name: 'Cities', id: 'cities', read: false, create: false, edit: false, delete: false },
    { name: 'Users', id: 'users', read: false, create: false, edit: false, delete: false },
    { name: 'Admins', id: 'admins', read: false, create: false, edit: false, delete: false },
    { name: 'Settings', id: 'settings', read: false, create: false, edit: false, delete: false },
  ]

  roles = []

  constructor(private adminService: AdminService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.admin = this.windowRef.config.context
    // Add new Roles
    this.admin.roles = _.cloneDeep(_.unionBy(this.admin.roles, this.defaultRoles, 'id'))

  }

  updateAdmin() {

    if (!this.admin.email) {
      return this.toastr.warning('', 'Email is Mandatory')
    }

    console.log(this.admin)

    this.adminService.updateAdmin(this.admin).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Admin  Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Admin')
    })
  }

}

