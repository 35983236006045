import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-college',
  templateUrl: './edit-college.component.html',
})
export class EditCollegeComponent {

  college: any
  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/colleges/logo'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.college = this.windowRef.config.context
    this.images = [this.college.logo]

  }

  updateCollege() {

    if (!this.college.collegeName) {
      return this.toastr.warning('', 'College Name is Mandatory')
    }

    if (!this.college.logo) {
      return this.toastr.warning('', 'Logo for College is Mandatory')
    }

    this.streamService.updateCollege(this.college).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('College  Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update College')
    })

  }

  onUploadFinished(event) {
    this.college.logo = event.serverResponse.response.body.path
  }

}

