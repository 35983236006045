import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService, CourseService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-group',
  templateUrl: './edit-group.component.html',
})
export class EditCourseGroupComponent {

  group: any
  images = []

  CKConfig: any = {
    toolbar: [['Styles', 'Format', 'Font', 'FontSize'],
      '/',
    ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste', 'Find', 'Replace', '-', 'Outdent', 'Indent', '-', 'Print'],
      '/',
    ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],],
  }

  API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  constructor(private courseService: CourseService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.group = this.windowRef.config.context
    this.images = [this.group.streamImage]

  }

  createStream() {

    if (!this.group.courseGroupName) {
      return this.toastr.warning('', 'Group Name Is Mandatory')
    }

    this.courseService.updateCourseGroup(this.group).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Group Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Group')
    })
  }

  onChange(event) {
    // console.log(event)
  }

}

