
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class LibraryService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getStreams() {
    return this.http.get(this.API_END_POINT + '/streams');
  }

  createStream(stream) {
    return this.http.post(this.API_END_POINT + '/streams', stream)
  }

  updateStream(stream) {
    return this.http.put(this.API_END_POINT + '/streams', stream)
  }

  updateStreamPositions(data) {
    return this.http.put(this.API_END_POINT + '/stream_positions', data)
  }

  getColleges() {
    return this.http.get(this.API_END_POINT + '/colleges');
  }

  createCollege(college) {
    return this.http.post(this.API_END_POINT + '/colleges', college)
  }

  updateCollege(college) {
    return this.http.put(this.API_END_POINT + '/colleges', college)
  }

  deleteCollege() {

  }

  getEntrances() {
    return this.http.get(this.API_END_POINT + '/entrances');
  }

  createEntrance(entrance) {
    return this.http.post(this.API_END_POINT + '/entrances', entrance)
  }

  updateEntrance(entrance) {
    return this.http.put(this.API_END_POINT + '/entrances', entrance)
  }

  deleteEntrance() {

  }


  /* Class Groups */

  getClassGroups() {
    return this.http.get(this.API_END_POINT + '/class_groups');
  }

  createClassGroup(classGroup) {
    return this.http.post(this.API_END_POINT + '/class_groups', classGroup)
  }

  updateClassGroup(classGroup) {
    return this.http.put(this.API_END_POINT + '/class_groups', classGroup)
  }

  deleteClassGroup() {

  }

  /* Scholarships */

  getScholarships() {
    return this.http.get(this.API_END_POINT + '/scholarships');
  }

  createScholarship(scholarship) {
    return this.http.post(this.API_END_POINT + '/scholarships', scholarship)
  }

  updateScholarship(scholarship) {
    return this.http.put(this.API_END_POINT + '/scholarships', scholarship)
  }

  deleteScholarship() {

  }
}
