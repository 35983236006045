import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

import { QuestionnarieService } from '../../../../@core/services';

@Component({
  selector: 'ngx-question-analytics',
  templateUrl: './question-analytics.component.html',
})
export class QuestionAnalyticsComponent {

  analytics = []
  questionId = ''

  constructor(private questionnarieService: QuestionnarieService,
    protected windowRef: NbWindowRef) {

    this.questionId = this.windowRef.config.context['questionId']

    this.questionnarieService.getAnalytics(this.questionId).subscribe((res: any) => {
      this.analytics = res.analytics
    })

  }

}

