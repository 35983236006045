
import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { CourseService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-course',
  templateUrl: './create-course.component.html',
})
export class CreateCourseComponent {

  course = {
    courseName: '',
    courseImage: '',
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/courses'

  constructor(private courseService: CourseService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createCourse() {

    if (!this.course.courseName) {
      return this.toastr.warning('', 'Course Name is Mandatory')
    }

    if (!this.course.courseImage) {
      return this.toastr.warning('', 'Image for Course is Mandatory')
    }

    this.courseService.createCourse(this.course).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Course  Created')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to add Course')
    })
  }

  onUploadFinished(event) {
    this.course.courseImage = event.serverResponse.response.body.path
  }

}

