import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { ContentService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-postlink',
  templateUrl: './edit-postlink.component.html',
})
export class EditPostLinkComponent {

  postLinkData
  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/entrances/logo'

  constructor(private contentService: ContentService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.postLinkData = _.cloneDeep(this.windowRef.config.context)
    this.images = [this.postLinkData.postImage]

  }

  updatePostLink() {

    if (!this.postLinkData.postTitle) {
      return this.toastr.warning('', 'Title is mandatory')
    }

    if (!this.postLinkData.postLink) {
      return this.toastr.warning('', 'Link is mandatory')
    }

    if (!this.postLinkData.postImage) {
      return this.toastr.warning('', 'Image for Post is mandatory')
    }


    this.contentService.updatePostLink(this.postLinkData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Post Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Post')
    })

  }

  onUploadFinished(event) {
    this.postLinkData.postImage = event.serverResponse.response.body.path
  }


}

