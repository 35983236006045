import { Component } from '@angular/core';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import _ from 'lodash';
import { environment } from '../../../../environments/environment'
import { UserService } from '../../../../app/@core/services';


@Component({
  selector: 'ngx-view-request',
  templateUrl: './view-request.component.html',
})
export class ViewRequestComponent {

  request: any
  API_URL = environment.API_URL
  rejectionReason = ''
  similarRequests = []

  constructor(
    protected windowRef: NbWindowRef,
    private userService: UserService,
    private windowService: NbWindowService,
  ) {
    this.request = this.windowRef.config.context['request']
  }

  acceptReport() {

    this.userService.acceptRequest(this.request.userId,
      this.request.email, this.request.countryCode, this.request.mobileNumber).subscribe((res: any) => {
        this.similarRequests = []
        this.windowRef.close()
      })

  }

  rejectReport() {

    if (!this.rejectionReason) {
      return alert('Please enter reason for Rejection')
    }

    this.userService.rejectRequest(this.request.userId, this.rejectionReason, this.request.email).subscribe((res: any) => {
      this.similarRequests = []
      this.windowRef.close()
    })
  }

  checkSimilarities(idNumber) {

    this.userService.similarityLookup(idNumber).subscribe((res: any) => {
      if (res.success) {
        this.similarRequests = res.requests
      }
    })
  }

  reapplyReport() {

    if (!this.rejectionReason) {
      return alert('Please enter reason for Reapply')
    }

    this.userService.reapplyRequest(this.request.userId, this.rejectionReason, this.request.email).subscribe((res: any) => {
      this.similarRequests = []
      this.windowRef.close()
    })
  }


}

