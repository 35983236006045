import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { SalesService } from '../../../@core/services';


@Component({
  selector: 'ngx-edit-purchase',
  templateUrl: './edit-purchase.component.html',
})
export class EditPurchaseComponent {

  purchase: any
  inProgress: Boolean = false

  constructor(private salesService: SalesService,
    protected windowRef: NbWindowRef,
    private toastr: NbToastrService) {


    this.purchase = this.windowRef.config.context
  }

  updatePurchase() {

    this.salesService.updatePurchase(this.purchase).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Success')
      }
    })

  }


  createInvoice() {
    this.inProgress = true
    this.salesService.createInvoice(this.purchase).subscribe((res: any) => {
      this.inProgress = false
      if (res.success) {
        this.toastr.success('', 'Success')
        return this.windowRef.close()
      }

      this.toastr.success('', res.message)
    })
  }


}
