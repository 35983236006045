
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class PurchaseService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getPurchases() {
    return this.http.get(this.API_END_POINT + '/purchases');
  }

}
