import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-class-group',
  templateUrl: './create-class-group.component.html',
})
export class CreateClassGroupComponent {

  classGroup = {
    classGroupName: '',
    classGroupImage: '',
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/class_groups'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createClassGroup() {

    if (!this.classGroup.classGroupName) {
      return this.toastr.warning('', 'Class Group Name is Mandatory')
    }

    if (!this.classGroup.classGroupImage) {
      return this.toastr.warning('', 'Logo for Class Name is Mandatory')
    }

    this.streamService.createClassGroup(this.classGroup).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Class Group Added !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Class Group')
    })

  }

  onUploadFinished(event) {
    this.classGroup.classGroupImage = event.serverResponse.response.body.path
  }

}

