import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService, AssessmentService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-assessment-questionnaire',
  templateUrl: './edit-questionnaire.component.html',
})
export class EditAssessmentQuestionnaireComponent {

  questionnaire: any
  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/questionnaries/questionnarie'

  constructor(protected windowRef: NbWindowRef,
    private toastr: NbToastrService,
    private assessmentService: AssessmentService) {

    this.questionnaire = this.windowRef.config.context
    this.images = [this.questionnaire.questionnaireImage]

  }

  updateQuestionnaire() {

    this.assessmentService.updateQuestionnarie(this.questionnaire).subscribe((res: any) => {

    })

  }

  onUploadFinished(event) {
    this.questionnaire.questionnaireImage = event.serverResponse.response.body.path
  }

}

