
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class CourseService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getCourse(courseId) {
    return this.http.get(this.API_END_POINT + '/courses/courses/' + courseId);
  }

  getCourses() {
    return this.http.get(this.API_END_POINT + '/courses/courses');
  }

  createCourse(course) {
    return this.http.post(this.API_END_POINT + '/courses/courses', course)
  }

  updateCourse(course) {
    return this.http.put(this.API_END_POINT + '/courses/courses', course)
  }

  getCourseGroups() {
    return this.http.get(this.API_END_POINT + '/courses/groups');
  }

  createCourseGroup(course) {
    return this.http.post(this.API_END_POINT + '/courses/groups', course)
  }

  updateCourseGroup(course) {
    return this.http.put(this.API_END_POINT + '/courses/groups', course)
  }

  addSection(section) {
    return this.http.post(this.API_END_POINT + '/courses/sections', section)
  }

  updateSection(section) {
    return this.http.put(this.API_END_POINT + '/courses/sections', section)
  }

  deleteSection(sectionId) {
    return this.http.delete(this.API_END_POINT + '/courses/sections/' + sectionId)
  }

  addVideo(video) {
    return this.http.post(this.API_END_POINT + '/courses/videos', video)
  }

  updateVideo(video) {
    return this.http.put(this.API_END_POINT + '/courses/videos', video)
  }

  deleteVideo(videoId) {
    return this.http.delete(this.API_END_POINT + '/courses/videos/' + videoId)
  }

  updateSectionVideoPositions(data, courseSectionId) {
    return this.http.put(this.API_END_POINT + '/courses/video_positions/' + courseSectionId, data)
  }

}
