
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class CityService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getCities() {
    return this.http.get(this.API_END_POINT + '/cities');
  }

  addCity(city) {
    return this.http.post(this.API_END_POINT + '/cities', city);
  }

  updateCity(city) {
    return this.http.put(this.API_END_POINT + '/cities', city);
  }

  removeCity(pincode) {
    return this.http.delete(this.API_END_POINT + '/cities/' + pincode);
  }

}
