import { Component } from '@angular/core';
import { NbToastrService, NbWindowService, NbWindowRef } from '@nebular/theme';
import _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService, LibraryService } from '../../../../@core/services';


@Component({
  selector: 'ngx-report-stream-match-editor',
  templateUrl: './stream-match-editor.component.html',
})
export class ReportStreamMatchEditorComponent {

  API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  streams = []
  questionnaireId = ''
  choice: any

  streamMatch = []


  constructor(
    private streamService: LibraryService,
    protected windowRef: NbWindowRef,
  ) {

    this.streamMatch = this.windowRef.config.context['streamMatch']
    this.streamMatch = _.cloneDeep(this.streamMatch)

    if (!Array.isArray(this.streamMatch)) {
      this.streamMatch = []
    }

    this.streamService.getStreams().subscribe((res: any) => {

      if (res.success) {
        this.streams = res.streams

        if (!this.streamMatch) {
          this.streamMatch = res.streams
        }

        this.streams.forEach((stream) => {

          this.streamMatch.forEach((match) => {
            if (stream.streamId === match.streamId) {
              stream.isSelected = match.isSelected
            }
          })

          if (!stream.isSelected) {
            stream.isSelected = false
          }
        })

      }

    })

  }

  saveStreamResultSetData() {
    this.windowRef.config.context = this.streams
    this.windowRef.close()
  }

}

