
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getUsers(offset) {
    return this.http.get(this.API_END_POINT + '/users/' + offset);
  }

  getUser(userId) {
    return this.http.get(this.API_END_POINT + '/user/details/' + userId);
  }

  updateUser(user) {
    return this.http.put(this.API_END_POINT + '/user/details', user);
  }

  resetQuestionnaireProgress(data) {
    return this.http.post(this.API_END_POINT + '/user/questionnaire/reset', data);
  }

  processQuestionnaireResult(data) {
    return this.http.post(this.API_END_POINT + '/user/questionnaire/score', data);
  }


  getQuestionnaireAnswers(data) {
    return this.http.post(this.API_END_POINT + '/user/questionnaire/answers', data);
  }

  searchKeyword(keyword) {
    return this.http.get(this.API_END_POINT + '/user_search/' + keyword);
  }

  downloadUsers(details) {

    this.http.post(this.API_END_POINT + '/users/download', details, { responseType: 'blob', observe: 'response' })
      .subscribe((response: any) => {

        const blob = new Blob([response.body], { type: 'text/csv' });
        saveAs(blob, 'users_keep_it_safe(delete_after_use).csv');

      });
  }

  downloadReport(url, name) {

    this.http.post(this.API_END_POINT + `/user/download_report?url=${url}&name=${name}`,
      { location: `report_${name}.pdf` }, { responseType: 'blob' })
      .subscribe((response: any) => {

        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, `report_${name}.pdf`);

      });

  }

  /* Plan Upgrade Requests */

  getPendingPlanUpgradeRequests(offset) {
    return this.http.get(this.API_END_POINT + '/plan-upgrade/pending/' + offset);
  }

  getAcceptedPlanUpgradeRequests(offset) {
    return this.http.get(this.API_END_POINT + '/plan-upgrade/accepted/' + offset);
  }

  getRejectedPlanUpgradeRequests(offset) {
    return this.http.get(this.API_END_POINT + '/plan-upgrade/rejected/' + offset);
  }

  acceptRequest(userId, email, countryCode, mobileNumber) {
    return this.http.put(this.API_END_POINT + '/plan-upgrade/accept/', { userId, email, countryCode, mobileNumber });
  }

  rejectRequest(userId, reason, email) {
    return this.http.put(this.API_END_POINT + '/plan-upgrade/reject/', { userId, reason, email });
  }

  reapplyRequest(userId, reason, email) {
    return this.http.put(this.API_END_POINT + '/plan-upgrade/reapply/', { userId, reason, email });
  }

  similarityLookup(idNumber) {
    return this.http.post(this.API_END_POINT + '/plan-upgrade/similarity-lookup/', { idNumber });
  }

  searchPendingPlanUpgradeRequests(keyword, offset) {
    return this.http.post(this.API_END_POINT + '/plan-upgrade/search/pending/' + offset, { keyword });
  }

  getPlanUpgradeRequest(requestId) {
    return this.http.get(this.API_END_POINT + '/plan-upgrade/request/' + requestId);
  }

  updatePlanUpgradeRequest(request) {
    return this.http.put(this.API_END_POINT + '/plan-upgrade/request/', request);
  }

}
