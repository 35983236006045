import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-stream',
  templateUrl: './edit-stream.component.html',
})
export class EditStreamComponent {

  stream: any
  images = []

  CKConfig: any = {
    toolbar: [['Styles', 'Format', 'Font', 'FontSize'],
      '/',
    ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste', 'Find', 'Replace', '-', 'Outdent', 'Indent', '-', 'Print'],
      '/',
    ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],],
  }

  API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.stream = this.windowRef.config.context
    this.images = [this.stream.streamImage]

    this.stream.classGroupMatch = _.cloneDeep(this.stream.classGroupMatch)

    if (!Array.isArray(this.stream.classGroupMatch)) {
      this.stream.classGroupMatch = []
    }

    this.streamService.getClassGroups().subscribe((res: any) => {
      if (res.success) {

        if (!this.stream.classGroupMatch) {
          this.stream.classGroupMatch = res.classGroups
        }

        res.classGroups.forEach((classGroup) => {

          this.stream.classGroupMatch.forEach((match) => {
            if (classGroup.classGroupId === match.classGroupId) {
              classGroup.score = match.score
            }
          })

          if (!classGroup.score) {
            classGroup.score = 0
          }

        })
        this.stream.classGroupMatch = res.classGroups

        console.log(this.stream.classGroupMatch)

      }

    })

  }

  updateStream() {

    if (!this.stream.streamName) {
      return this.toastr.warning('', 'Stream Name Is Mandatory')
    }

    if (!this.stream.streamImage) {
      return this.toastr.warning('', 'Image for Stream Is Mandatory')
    }

    this.streamService.updateStream(this.stream).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Stream  Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Stream')
    })
  }

  onUploadFinished(event) {
    this.stream.streamImage = event.serverResponse.response.body.path
  }

  onStreamNameChange() {
    this.stream.slug = this.stream.streamName.toLocaleLowerCase()
  }

  onChange(event) {
    // console.log(event)
  }

}

