import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-stream',
  templateUrl: './create-stream.component.html',
})
export class CreateStreamComponent {

  stream = {
    streamName: '',
    streamImage: '',
    slug: ''
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

  }

  createStream() {

    if (!this.stream.streamName) {
      return this.toastr.warning('', 'Stream Name Is Mandatory')
    }

    if (!this.stream.streamImage) {
      return this.toastr.warning('', 'Image for Stream Is Mandatory')
    }

    this.streamService.createStream(this.stream).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Stream  Created')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to add Stream')
    })
  }

  onUploadFinished(event) {
    this.stream.streamImage = event.serverResponse.response.body.path
  }

  onStreamNameChange() {
    this.stream.slug = this.stream.streamName.toLocaleLowerCase()
  }

}

