import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { LibraryService } from '../../../../@core/services';

@Component({
  selector: 'ngx-edit-class-group',
  templateUrl: './edit-class-group.component.html',
})
export class EditClassGroupComponent {

  classGroup: any
  images = []
  images_TA = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/class_groups'

  constructor(private streamService: LibraryService, protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.classGroup = this.windowRef.config.context
    this.images = [this.classGroup.classGroupImage]
    this.images_TA = [this.classGroup.classGroupImage_TA]
  }

  updateClassGroup() {

    if (!this.classGroup.classGroupName) {
      return this.toastr.warning('', 'Class Name is Mandatory')
    }

    if (!this.classGroup.classGroupImage) {
      return this.toastr.warning('', 'Image for Class is Mandatory')
    }

    this.streamService.updateClassGroup(this.classGroup).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success('Class Group  Updated !')
        return this.windowRef.close();
      }

      this.toastr.danger('Unable to update Class Group')
    })

  }

  onUploadFinished(event) {
    this.classGroup.classGroupImage = event.serverResponse.response.body.path
  }

  onUploadFinishedTA(event) {
    this.classGroup.classGroupImage_TA = event.serverResponse.response.body.path
  }
}

