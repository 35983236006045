
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable()
export class AssessmentService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getQuestionnaries(offset) {
    return this.http.get(this.API_END_POINT + '/assessments/questionnaires/' + offset);
  }

  createQuestionnaire(questionnaire) {
    return this.http.post(this.API_END_POINT + '/assessments/questionnaires', questionnaire)
  }

  getQuestions(questionnaireId) {
    return this.http.get(this.API_END_POINT + '/assessments/questionnaire/questions/' + questionnaireId);
  }

  updateQuestionnarie(questionnaire) {
    return this.http.put(this.API_END_POINT + '/assessments/questionnaires', questionnaire)
  }

  addQuestion(questionnaireId, question) {
    return this.http.post(this.API_END_POINT + '/assessments/questionnaire/questions/' + questionnaireId, question);
  }

  removeQuestion(questionId) {
    return this.http.delete(this.API_END_POINT + '/assessments/questionnaire/questions/' + questionId);
  }

  updateQuestion(question) {
    return this.http.put(this.API_END_POINT + '/assessments/questionnaire/questions/' + question.questionId, question);
  }

  addQuestionOptions(option) {
    return this.http.post(this.API_END_POINT + '/assessments/questionnaire/options/' + option.questionId, option);
  }

  removeQuestionOptions(questionChoiceId) {
    return this.http.delete(this.API_END_POINT + '/assessments/questionnaire/options/' + questionChoiceId);
  }

  updateQuestionOptions(option) {
    return this.http.put(this.API_END_POINT + '/assessments/questionnaire/options/' + option.optionId, option);
  }

  getAnalytics(questionId) {
    return this.http.get(this.API_END_POINT + '/assessments/questionnaire/analytics/' + questionId);
  }


}
