import { Component } from '@angular/core';
import { NbToastrService, NbWindowService, NbWindowRef } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService } from '../../../../@core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-choice-score-editor',
  templateUrl: './choice-score-editor.component.html',
})
export class ChoiceScoreEditorComponent {

  // API_URL = environment.API_END_POINT + '/images/upload?path=images/streams'

  resultSets = []
  questionnaireId = ''
  choice: any

  constructor(private questionnarieService: QuestionnarieService,
    private toastr: NbToastrService,
    protected windowRef: NbWindowRef) {
    this.questionnaireId = this.windowRef.config.context['questionnaireId']
    this.choice = this.windowRef.config.context['choice']
    const originalResultSets = this.choice.resultSetScrores
    console.log(originalResultSets)
    this.questionnarieService.getResultSets(this.questionnaireId).subscribe((res: any) => {
      this.resultSets = res.resultSets
      this.resultSets.forEach((resultSet) => {
        originalResultSets.forEach((originalSet) => {
          if (resultSet.resultSetId == originalSet.resultSetId) {
            resultSet.score = originalSet.score
          }
        })

        if (!resultSet.score) {
          resultSet.score = 0
        }
      })
    })

  }

  saveQuestionChoiceScore() {
    const scoreEval = []
    this.resultSets.forEach((set, index) => {
      scoreEval.push({
        resultSetId: set.resultSetId,
        resultSetName: set.resultSetName,
        score: set.score
      })
    })

    this.choice.resultSetScrores = scoreEval

    this.questionnarieService.updateQuestionOptions(this.choice).subscribe((res: any) => {
      if (res.success) {
        this.windowRef.close()
      }
    })

  }

}

