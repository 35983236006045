
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class SettingsService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getSettings() {
    return this.http.get(this.API_END_POINT + '/settings');
  }

  getPlans() {
    return this.http.get(this.API_END_POINT + '/settings/plans');
  }

}
