export * from './admin.service';
export * from './assessment.service';
export * from './city.service';
export * from './content.service';
export * from './course.service';
export * from './dashboard.service';
export * from './department.service';
export * from './purchase.service';
export * from './questionnarie.service';
export * from './sales.service';
export * from './session.service';
export * from './settings.service';
export * from './stream.service';
export * from './user.service';

