
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class AdminService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getAdmins() {
    return this.http.get(this.API_END_POINT + '/admins');
  }

  updateAdmin(admin) {
    return this.http.put(this.API_END_POINT + '/admins', admin);
  }

  getReportData(key) {
    return this.http.get(this.API_END_POINT + '/report_data/' + key);
  }

  updateReportData(key, data) {
    return this.http.put(this.API_END_POINT + '/report_data/' + key, data);
  }

}
