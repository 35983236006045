import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-user-answers',
  templateUrl: './user-answers.component.html',
})
export class UserAnswersComponent {

  answers = []

  constructor(protected windowRef: NbWindowRef, private toastr: NbToastrService) {

    this.answers = this.windowRef.config.context['answers']

  }

}

