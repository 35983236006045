
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class SessionService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getSessions() {
    return this.http.get(this.API_END_POINT + '/sessions');
  }

  getSessionSubscribers(offset) {
    return this.http.get(this.API_END_POINT + '/sessions/subscribers/' + offset);
  }

  getSessionRequests(offset) {
    return this.http.get(this.API_END_POINT + '/sessions/requests/' + offset);
  }

}
