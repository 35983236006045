
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class DashboardService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getStats(dateRange) {
    return this.http.post(this.API_END_POINT + '/dashboard/stats', dateRange);
  }

}
