import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';


@Component({
  selector: 'ngx-assessment-bird-eye-view',
  templateUrl: './bird-eye-view.component.html',
})
export class AssessmentBirdEyeViewQuestionnaireComponent {

  questions: any

  constructor(protected windowRef: NbWindowRef) {

    this.questions = this.windowRef.config.context
    console.log(this.questions)

  }

}

