import { Component } from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';

import { environment } from '../../../../../environments/environment';
import { QuestionnarieService } from '../../../../@core/services';

@Component({
  selector: 'ngx-create-questionnaire',
  templateUrl: './create-questionnaire.component.html',
})
export class CreateQuestionnaireComponent {

  questionnaire = {
    questionnaireName: '',
    questionnaireImage: '',
  }

  images = []

  API_URL = environment.API_END_POINT + '/images/upload?path=images/questionnaries/questionnarie'

  constructor(protected windowRef: NbWindowRef, private toastr: NbToastrService, private questionnarieService: QuestionnarieService) {

  }

  createQuestionnaire() {

    if (!this.questionnaire.questionnaireName) {
      return this.toastr.warning('', 'Questionnaire Name is Mandatory')
    }

    this.questionnarieService.createQuestionnarie(this.questionnaire).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('', 'Questionnarie Created !')
        return this.windowRef.close()
      }
      this.toastr.warning('', 'Unable to create Questionnarie')
    })

  }

  onUploadFinished(event) {
    this.questionnaire.questionnaireImage = event.serverResponse.response.body.path
  }


}

