
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class DepartmentService {

  API_END_POINT = environment.API_END_POINT;

  constructor(private http: HttpClient) { }

  getDepartments() {
    return this.http.get(this.API_END_POINT + '/departments');
  }
  getDepartmentsById(departmentId) {
    return this.http.get(this.API_END_POINT + '/departments/' + departmentId);
  }

  createDepartment(Department) {
    return this.http.post(this.API_END_POINT + '/departments', Department)
  }

  updateDepartment(Department) {
    return this.http.put(this.API_END_POINT + '/departments', Department)
  }

  updateDepartmentPositions(data) {
    return this.http.put(this.API_END_POINT + '/department_positions', data)
  }

  getContent(departmentId) {
    return this.http.get(this.API_END_POINT + '/content/' + departmentId)
  }

  updateContent(content) {
    return this.http.put(this.API_END_POINT + '/content/' + content.departmentId, content)
  }

}
